import React from "react";
import { Button, Modal, OverlayTrigger, Popover, Tooltip } from "react-bootstrap";
import {
  ExclamationTriangleFill,
  InfoCircle,
  PrinterFill,
  QuestionCircleFill,
  SortDownAlt,
  SortUp,
} from "react-bootstrap-icons";
import { OverlayInjectedProps } from "react-bootstrap/Overlay";
import Spinner from "react-bootstrap/Spinner";
import { useTranslation } from "react-i18next";
import { getAppleAuthUrl } from "../helpers/appleSignin";
import HourglassGlobals from "../helpers/globals";
import appleLogo from "../img/apple_id_signin.svg";

export function PrintButton(props: { onClick?: () => void; disabled?: boolean; className?: string }) {
  const { t } = useTranslation();
  const onClick = props.onClick ? props.onClick : () => window.print();
  const className = props.className ?? "py-2";

  return (
    <Button
      className={`d-print-none ${className}`}
      variant="secondary"
      onClick={onClick}
      aria-label={t("general.print")}
      disabled={props.disabled}
    >
      <PrinterFill className="me-1" size={18} />
      <span>{t("general.print")}</span>
    </Button>
  );
}

export function SuspenseFallback() {
  return <Spinner className="position-absolute top-50 start-50" animation="border" variant="primary" />;
}

export function ErrorModal(props: {
  show: boolean;
  setShow: (show: boolean) => void;
  message: string;
  title?: string;
}) {
  const { t } = useTranslation();

  const handleClose = () => {
    props.setShow(false);
  };

  return (
    <Modal show={props.show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{props.title ? props.title : t("popup.error.title.generic")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ExclamationTriangleFill className="warning-color me-2" size="2em" /> {props.message}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          {t("popup.error.button.ok")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export function AppleIDIcon(props: { className?: string; testId?: string }) {
  return (
    <div className={`apple-id-div ${props.className ?? ""}`} data-testid={props.testId}>
      <img alt="apple" src={appleLogo} />
    </div>
  );
}

export function AppleSignInButton(props: { noLink?: boolean; disabled?: boolean }) {
  const { t } = useTranslation();

  return (
    <a
      className="apple-signin-button"
      href={
        props.noLink || props.disabled
          ? "#"
          : getAppleAuthUrl(
              window.HGGlobal?.state || "state",
              HourglassGlobals.appleAuthRedirectURI,
              window.HGGlobal?.nonce || "nonce",
            )
      }
    >
      <img alt="apple" src={appleLogo} />
      <span>{t("login.sign-in-apple")}</span>
    </a>
  );
}

export function InfoPopover(props: { text: string }) {
  return (
    <OverlayTrigger
      trigger="click"
      rootClose={true}
      placement="right"
      overlay={
        <Popover>
          <Popover.Body>{props.text}</Popover.Body>
        </Popover>
      }
    >
      <InfoCircle className="infoButton ms-1" />
    </OverlayTrigger>
  );
}

export function SortIndicator<T>(props: {
  changeSort: (sortBy: T, direction: boolean) => (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void;
  sortBy: T;
  currentSortBy: T;
  currentSortDirection: boolean;
}) {
  const upColor = props.sortBy === props.currentSortBy && !props.currentSortDirection ? "blue" : "gray";
  const downColor = props.sortBy === props.currentSortBy && props.currentSortDirection ? "blue" : "gray";
  return (
    <span className="ms-2">
      <button className="no-border" onClick={props.changeSort(props.sortBy, false)}>
        <SortUp color={upColor} />
      </button>
      <button className="no-border" onClick={props.changeSort(props.sortBy, true)}>
        <SortDownAlt color={downColor} />
      </button>
    </span>
  );
}

export function HGPageTitle(props: { title: string; subtitle?: string }) {
  return (
    <div>
      {props.subtitle && <h5 className="fw-light text-uppercase m-0 text-muted">{props.subtitle}</h5>}
      <h2 className="m-0">{props.title}</h2>
    </div>
  );
}

export function HelpButton(props: { text: string; className?: string }) {
  const tooltip = (overlayProps: OverlayInjectedProps) => <Tooltip {...overlayProps}>{props.text}</Tooltip>;

  return (
    <OverlayTrigger overlay={tooltip} placement="auto-end" trigger="click">
      <Button size="sm" variant="link" className={props.className} style={{ border: "none" }}>
        <QuestionCircleFill size={16} />
      </Button>
    </OverlayTrigger>
  );
}
